import {
  abTestEligible,
  pageview,
  impression,
  internalClick,
  internalRightClick,
  externalClick,
  externalRightClick,
  contentClientLayer,
  webContentLayer,
  dataInternalLayer,
  dataExternalLayer,
  dataSourceLayer,
  dataABTestLayer,
  dataContentActionLayer,
  contentActionClick,
  CONTEXT_PAGE_TYPES,
  CONTEXT_PAGE_IDS
} from '@buzzfeed/pixiedust';
import {
  createClickHandler,
  createRightClickHandler,
  combineEvents,
  createUnitTrackingHandlers
} from '@buzzfeed/bf-utils';
import { setEnv } from '@buzzfeed/pixiedust/lib/web-bf';

import {getExperimentId} from '@buzzfeed/buzzblocks/js/services/abeagle/standalone';
import cookies from '@buzzfeed/buzzblocks/js/services/cookies';

setEnv(BZFD.Config.env);

function contextPageId(pageName) {
  switch (pageName) {
  case 'home':
    return CONTEXT_PAGE_IDS.HOMEPAGE;
  case 'vertical':
    return CONTEXT_PAGE_IDS.SECTION;
  case 'tag':
    return CONTEXT_PAGE_IDS.TAG;
  case 'badge':
    return CONTEXT_PAGE_IDS.BADGE;
  default:
    return CONTEXT_PAGE_IDS.TOPIC;
  }
}

const createExternalLinksClicksHandler = (
  clickEvent,
  rightClickEvent
) => combineEvents(
  createClickHandler(clickEvent),
  createRightClickHandler(rightClickEvent)
);

const createContentActionClicksHandler = (
  click
) => createClickHandler(click);

const createPixiedustV3Event = ([sendEvent, addLayers]) => {
  addLayers(
    contentClientLayer({
      page_edition: BZFD.Context.page.localization.country,
      context_page_id: contextPageId(BZFD.Context.page.name),
      context_page_type: CONTEXT_PAGE_TYPES.FEED
    })
  );
  return [sendEvent, addLayers];
};

export const sendPageviewV3 = () => {
  const [sendEvent] = createPixiedustV3Event(pageview());

  return sendEvent();
};

export const sendABTestEvent = async() => {
  let experimentId = await getExperimentId();
  if (BZFD.Context.page.name === 'home') {
    const experimentCookie = 'dsl25_frhf';
    const trackingCookie = `${experimentCookie}_tracked`;
    const variantExperimentCookie = cookies.get(experimentCookie);
    const variantTrackingCookie = cookies.get(trackingCookie);
    if(variantExperimentCookie) {
      experimentId.push(`${experimentCookie}|${experimentCookie}|1|${variantExperimentCookie}|1`);
    }
    if(variantTrackingCookie) {
      experimentId.push(`${trackingCookie}|${experimentCookie}|1|1|1`);
    }
  }
  const [sendEvent, addLayers] = createPixiedustV3Event(abTestEligible());
  addLayers(dataABTestLayer(experimentId));
  return sendEvent();
};

const createPixiedustUnitTracking = () => createUnitTrackingHandlers(
  createPixiedustV3Event(impression()),
  createPixiedustV3Event(internalClick()),
  createPixiedustV3Event(internalRightClick())
);

const createExternalLinksUnitTracking = () => createExternalLinksClicksHandler(
  createPixiedustV3Event(externalClick()),
  createPixiedustV3Event(externalRightClick())
);

const createExternalLinksUnitTrackingWithImpressions = () => createUnitTrackingHandlers(
  createPixiedustV3Event(impression()),
  createPixiedustV3Event(externalClick()),
  createPixiedustV3Event(externalRightClick())
);

const createContentActionUnitTracking = () => createContentActionClicksHandler(
  createPixiedustV3Event(contentActionClick())
);

/**
 * Binds impression and click tracking to a given element
 * @param {Object} data - object containing element and data in pixiedust v3 structure
 * @return {void}
 */
export const bindPixiedustProductTracking = (element, data) => {
  if (!element || !data) {
    return;
  }
  let attachHandlers;
  let addLayers;
  const layers = [
    webContentLayer(data),
    dataInternalLayer(data),
    dataSourceLayer(data)
  ];
  if(data.target_content_url) {
    layers.push(dataExternalLayer(data));
    [attachHandlers, addLayers] = createExternalLinksUnitTrackingWithImpressions();
  } else {
    [attachHandlers, addLayers] = createPixiedustUnitTracking();
  }
  addLayers(...layers);
  attachHandlers(element);
};

/**
 * Binds unit tracking to all `data-pixiedust` elements inside the specified root
 * @param {HTMLElement}
 * @return {void}
 */
export const bindPixiedustUnitTracking = singleItem => {
  if (!singleItem) {
    return;
  }

  singleItem.querySelectorAll('[data-pixiedust]').forEach(element => {

    if(!element.getAttribute('data-pixiedust').length) {
      return;
    }

    const itemData = JSON.parse(decodeURIComponent(element.getAttribute('data-pixiedust')));

    if(itemData.target_content_url) {
      const [attachHandlers, addLayers] = createExternalLinksUnitTracking();
      addLayers(
        webContentLayer(itemData),
        dataExternalLayer(itemData)
      );
      attachHandlers(element);
      return;
    }

    if(itemData.action_type && itemData.action_value) {
      const [attachHandlers, addLayers] = createContentActionUnitTracking();
      addLayers(
        webContentLayer(itemData),
        dataContentActionLayer(itemData)
      );
      attachHandlers(element);
      return;
    }

    const [attachHandlers, addLayers] = createPixiedustUnitTracking();
    addLayers(
      webContentLayer(itemData),
      dataInternalLayer(itemData),
      dataSourceLayer(itemData)
    );
    attachHandlers(element);
  });
};
